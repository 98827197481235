<template>
  <div>
    <log-title></log-title>
    <div id="logout">
      <div class="content">
        <el-image :src="require('../../assets/img/login/logout.png')" />
        <div class="test">
          <div>登录超时，请重新</div>
          <div class="login" @click="getLogin">登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logTitle from "./logTitle.vue";

export default {
  components: { logTitle },
  name: "logout",
  methods: {
    getLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="less" scoped>
#logout {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    display: flex;
    flex-direction: column;
    .test {
      display: flex;
      justify-content: center;
      margin-top: 6px;
      .login {
        display: inline-block;
        color: var(--pink);
        cursor: pointer;
      }
    }
  }
}
</style>